import { useState } from "react";
import InputBox from "../inputBox";
import './tags.scss';
import { Icons } from "../../lib/constants";


const Tags = ({ values, onAdd, onRemove, placeholder='', label, readOnly=false}) => {
    const [tag, setTag] = useState('')

    return <div className="tagsContainer">
        {label && <label className={"label"}>{label}</label>}
        {!readOnly && <InputBox 
            value={tag} 
            onChange={(({target: { value }}) => setTag(value))} 
            placeholder={placeholder} 
            onSubmit={() => {if (tag.trim() !== '') onAdd({target: { value: tag }})}}
        />}
        <div className="tagBubbles flex">
            {values.map(value => <div className="tagBubble flex alignCenter justifyCenter" key={value}>
                <span className="tagText">{value}</span>
                <span className="flex" onClick={() => onRemove({target: { value }})}>{Icons.CANCEL}</span>
            </div>)}
        </div>
    </div>
}

export default Tags;
