import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MoveToBillPrivileges } from '../../lib/constants';
import { FileUpload, Spinner, Table } from '../../components';
import { cookieService } from '../../lib/helpers';
import axios from 'axios';
import AEConfig from '../../lib/ae-config.json';
import { saveAs } from 'file-saver';


export default function Generate() {
	const navigate = useNavigate();

	const me = useSelector(state => state.app.me);

	const [errors, setErrors] = useState([]);
	const [inputFile, setInputFile] = useState();
	const [waiting, setWaiting] = useState(false);

	useEffect(() => {
		let canView = me.claims.filter(c => c.name === MoveToBillPrivileges.GENERATE_MTB_SHEETS).length > 0;

		// Move to distributors, if config claim not found, move to home
		if (!canView) navigate('/move-to-bill/distributors');

		// No need to use setEdit, only one claim needed for this page, which allows them to do everything
	}, [navigate, me]);

	const generateFiles = async e => {
		setInputFile(e.target.files[0]);
		setWaiting(true);
		const formData = new FormData();
		formData.append('file', e.target.files[0]);

		const cookie = cookieService.get('ae_app_user_jwt');

		return await axios
			.post(AEConfig.API_URL + '/move-to-bill/generateSheets', formData, {
				headers: {
					Authorization: 'Bearer ' + cookie,
					'content-type': 'multipart/form-data',
				},
			})
			.then(async response => {
				// Decode the base64 string
				const binaryString = atob(response.data.data);

				// Create a Uint8Array to hold the binary data
				const binaryData = new Uint8Array(binaryString.length);

				for (let i = 0; i < binaryString.length; i++) {
					binaryData[i] = binaryString.charCodeAt(i);
				}

				// Create a Blob from the binary data
				const blob = new Blob([binaryData], { type: 'application/zip' });

				// Use file-saver to download the file
				saveAs(blob, 'enrollment_files.zip');
				setErrors([]);
				setInputFile('');
				setWaiting(false);
			})
			.catch(err => {
				setErrors(err.response.data.data);
				setInputFile('');
				setWaiting(false);
			});
	};

	// const downloadErrorReport = () => {
	// 	downloadCSVFromJson(
	// 		'errors.csv',
	// 		errors.map(err => ({
	// 			...err,
	// 		})),
	// 	);
	// };

	const cellStyle = {
		padding: "20px 15px",
		textAlign: 'center'
	}

	return (
		<>
			{waiting ? (
				<div className="flex justifyCenter alignCenter" style={{ height: '100%' }}>
					<Spinner size="l" text={'Generating output files'} />
				</div>
			) : (
				<>
					<FileUpload label="Upload input file" onChange={generateFiles} value={inputFile} />
					{errors.length > 0 && (
						<>
							<div style={{ width: '100%', overflow: 'auto' }}>
								<Table
									searchBox={false}
									data={errors}
									columns={[
										{ key: 'utility', title: 'Utility' , styles: cellStyle},
										{ key: 'commodity', title: 'Commodity', styles: cellStyle },
										{ key: 'account_number', title: 'Account Number', styles: cellStyle },
										{ key: 'product', title: 'Product', styles: cellStyle },
										{ key: 'bill_type', title: 'Bill Type', styles: cellStyle },
										{ key: 'customer_name', title: 'Customer', styles: cellStyle },
										{ key: 'salesperson', title: 'Salesperson', styles: cellStyle },
										{ key: 'salesperson2', title: 'Salesperson2', styles: cellStyle },
										{ key: 'error_message', title: 'Error', styles: cellStyle },
									]}
									addGrid
								/>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}
