import React, { useState } from 'react'
import './tabs.scss'

const defaultOpt = [{
    name: 'tab',
    title: 'Tab',
    element: <div>Example</div>
}]

// Don't remove ...props, they can come from other components that pass it forward
export default function Tabs ({children, options = defaultOpt, ...props}){
    const [option, setOption] = useState(options[0])
    
    return <div className='tabsContainer flex-column'>
        <div className={'tabs'}>
            {options.map((opt, o) => <div key={o} 
                className={'tab flex justifyCenter alignCenter' + (option.name === opt.name ? ' selected' : '')}
                onClick={e => {
                    e.stopPropagation();
                    setOption(options[o])
                }}
            >{opt.title} </div>)}
        </div>
        {option && <div className='tabContent'>
            {React.cloneElement(option.element, {...props})}
        </div>}
    </div>
}
