import ReRoute from '../lib/helpers/reRoute'
import { Generate, Agents, Distributors, PricingPlans } from '../modules/moveToBill'

const MoveToBillRoutes = {
    path: 'move-to-bill',
    element: <ReRoute reRoute={'/move-to-bill/generate'} />,
    subRoutes: [
        {
            path: 'generate',
            component: Generate
        },
        {
            path: 'agents',
            component: Agents
        },
        {
            path: 'distributors',
            component: Distributors
        },
        {
            path: 'pricing-plans',
            component: PricingPlans
        }
    ]
}

export default MoveToBillRoutes
