import { Form } from "../../../components";
import { useState } from "react";
import { MoveToBillActions } from "../../../redux/actions";


const PricingPlanComponents = ({ canEdit, pricingPlan={ components: {} }, dispatch, cancelEdit, setProcessing }) => {
    const [values, setValues] = useState(pricingPlan.components);
	const [errors, setErrors] = useState({});

    const flagNames = {
        "losses": "Losses", 
        "rps": "RPS",
        "load_following": "Load Following",
        "tec": "TEC",
        "basis": "Basis",
        "nits": "NITS",
        "ancillaries": "Ancillaries",
        "energy": "Energy",
        "capacity": "Capacity",
        "arr": "ARR"
    }

    const fields = [
        ...["losses", "rps", "load_following", "tec", "basis", "nits", "ancillaries", "energy", "capacity", "arr"].map(fieldName => ({
            fieldName,
            label: null,
            type: 'checkbox',
            options: [{id: fieldName, name: flagNames[fieldName] || fieldName, checked: !!values[fieldName]}],
            onClick: () => setValues({...values, [fieldName]: !values[fieldName]})
        }))
    ]   

    const actions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit },
		{ text: 'Update', type: 'submit' },
    ]

    const onSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        dispatch({
            type: MoveToBillActions.types.UPDATE_PRICING_PLAN_COMPONENTS, 
            payload: {
                id: pricingPlan.id,
                update: values
            }
        })
    }

    return <Form fields={fields} canEdit={canEdit} values={values} setValues={setValues} errors={errors} onSubmit={onSubmit} actions={actions}/>
}

export default PricingPlanComponents;
