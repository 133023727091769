import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MoveToBillActions } from '../../redux/actions';
import { Form, DataCard, Tabs, Spinner, InputBox, SelectBox } from '../../components';
import { Icons, MoveToBillPrivileges } from '../../lib/constants';
import { capitalizeFirstLetter } from '../../lib/helpers';


function DistributorOverview({canEdit, distributor, dispatch, cancelEdit, setProcessing}) {
    useEffect(() => {
        if (!distributor.territory_codes) {
            setProcessing(true)
            dispatch(MoveToBillActions.getDistributor(distributor.id))
        }
    }, [distributor.territory_codes, distributor.id, dispatch])

    const [values, setValues] = useState(distributor);
	const [errors, setErrors] = useState({});

	const onSubmit = e => {
		e.preventDefault();
		let errObj = {};

		if (!values.name || values.name.trim() === '') errObj = { ...errObj, name: 'Enter valid distributor name' };

		if (Object.keys(errObj).length > 0) {
			setErrors(errObj);
			return;
		}

        setProcessing(true)
        // If exists
        if (!!distributor.id) dispatch(MoveToBillActions.updateDistributor(values))
        // If not 
        else dispatch(MoveToBillActions.createDistributors(values))
    };

    const fieldNames = {
        "therm_code": "Therm Code",
        "ae_code": 'AE Code',
        "vendor_id": 'ESG Distributor ID',
        "duns": "DUNS",
        "iso_name": "ISO"
    }

    const fieldOnChange = {
        'vendor_id': ({ target: { value } }) => setValues({ ...values, vendor_id: parseInt(value)})
    }

	const fields = [
		{
			fieldName: 'name',
			label: 'Name',
			value: values.name,
			readOnly: !!values.id,
			onChange: ({ target: { value } }) => setValues({ ...values, name: value }),
		},
        // General rules for fields that are update-able
        ...[ 'duns', 'state', "iso_name", 'ae_code', 'therm_code', 'vendor_id', 'description'].map(field => ({
			fieldName: field,
			label: fieldNames[field] || capitalizeFirstLetter(field),
			value: values[field],
			readOnly: !canEdit,
			onChange: fieldOnChange[field] || (({ target: { value } }) => setValues({ ...values, [field]: value }))
		}))
	];

	const formActions = [
		{ buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit },
		{ text: values.id ? 'Update' : 'Create', type: 'submit' },
	];

	return <Form className={'fullWidth'} fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />;
}

function TerrityCode({idx, commodity, code, availableCommodities, onDelete, onEdit, canEdit}){
    return <tr>
        <td style={{width: '47.5%'}}>
            {commodity 
                ? <InputBox readOnly={true} value={commodity} fullWidth/> 
                : <SelectBox 
                    value={commodity} 
                    options={availableCommodities}
                    singleSelectOnly={true}
                    onChange={({target: { value }}) => onEdit({idx: null, code: '', commodity: value})}
                    
                />
            }
        </td>
        <td style={{width: '47.5%'}}>
            {commodity && <InputBox fullWidth value={code} onChange={({target: { value }}) => onEdit({idx, code: value, commodity})} />}
        </td>
        <td>{commodity && canEdit && <div onClick={() => onDelete(idx)}>{Icons.DELETE}</div>}</td>
    </tr>
}

function DistributorTerritoryCodes({canEdit, distributor, dispatch}) {
    const [values, setValues] = useState(distributor.territory_codes)
  
    const handleTCDelete = (idx) => setValues(values.filter((v, i) => i !== idx))

    const handleTCEdit = ({idx, code, commodity}) => {
        if (idx === null){
            setValues([...values, {code, commodity}])
        }
        else setValues(values.map((tc, tcx) => {
            if (tcx === idx) return {code, commodity}
            return tc
        }))
    }

    const availableCommodities = ['Gas', 'Electric'].filter(
        comm => !values.map(x => x.commodity).includes(comm)
    ).map(comm => ({id: comm, name: comm}))

    return <table className='fullWidth'>
        <thead>
            <tr>
                <th>Commodity</th>
                <th>Code</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {values.map((tc, idx) => <TerrityCode key={idx}
                idx={idx}
                commodity={tc.commodity}
                code={tc.code}
                availableCommodities={['Gas']}
                onDelete={handleTCDelete}
                onEdit={handleTCEdit}
                canEdit={canEdit}
            />)}
            {canEdit && availableCommodities.length > 0 && <TerrityCode 
                availableCommodities={availableCommodities}
                onDelete={handleTCDelete}
                onEdit={handleTCEdit}
                canEdit={canEdit}
            />}
        </tbody>
    </table>
}

function DistributorBillTypes({distributor, canEdit, dispatch}) {
    const distributor_bill_types = distributor.bill_types.filter(dbt => !dbt.end_date || new Date(dbt.end_date) > new Date())

    const billTypes = [
        {
            "commodity": "Gas",
            "dual": distributor_bill_types.filter(
                dbt => dbt.commodity === 'Gas' && dbt.bill_method_description === 'Dual'
            ).length > 0,
            "single": distributor_bill_types.filter(
                dbt => dbt.commodity === 'Gas' && dbt.bill_method_description !== 'Dual'
            )
        },
        {
            "commodity": "Electric",
            "dual": distributor_bill_types.filter(
                dbt => dbt.commodity === 'Electric' && dbt.bill_method_description === 'Dual'
            ).length > 0,
            "single": distributor_bill_types.filter(
                dbt => dbt.commodity === 'Electric' && dbt.bill_method_description !== 'Dual'
            )
        }
    ]

    for (let dx in billTypes) {
        if (billTypes[dx]['single'].length === 0) billTypes[dx]['single'] = null
        else billTypes[dx]['single'] = billTypes[dx]['single'][0]['bill_method_description'].includes('Rate') ? 'URR' : 'UBR'
    }
    
    return <table className='fullWidth'>
        <thead>
            <tr>
                <th>Commodity</th>                
                <th>Single Bill Type</th>
                <th>Offers Dual</th>
            </tr>
        </thead>
        <tbody>
            {billTypes.filter(dbt => !!dbt.single).map((dbt, i) => <tr key={i} style={{textAlign: 'center', lineHeight: '35px'}}>
                    <td>{dbt.commodity}</td>
                    <td>{dbt.single}</td>
                    <td>{dbt.dual ? Icons.CHECK : <></>}</td>
                </tr>
            )}
        </tbody>
    </table>
}

function DistributorServiceRegions({ distributor }) {
    const [zipCode, setZipCode] = useState('')

    const {service_regions } = distributor

    return <>
    <InputBox placeholder={'Search by zip code'} value={zipCode} onChange={({target: { value }}) => setZipCode(value.trim())}/>
    <div style={{'height': '200px', overflow:'auto'}}>
        <table style={{width: '100%'}}>
            <thead>
                <tr style={{ lineHeight: '25px' }}>
                    <th>City</th>
                    <th>County</th>
                    <th>State</th>
                    <th>Zip Code</th>
                </tr>
            </thead>
            <tbody>
                {service_regions
                .filter(sr => sr.zip_code.includes(zipCode))
                .map(({city, county, state, zip_code}, s) => <tr key={s} style={{textAlign: 'center', lineHeight: '25px'}}>
                    <td style={{width: '25%'}}>{city}</td>
                    <td style={{width: '25%'}}>{county}</td>
                    <td style={{width: '25%'}}>{state}</td>
                    <td style={{width: '25%'}}>{zip_code}</td>
                </tr>)}
            </tbody>
        </table>
    </div>
    
    </>
}

function DistributorCard({canEdit, distributor, dispatch, inAppLoading=false }) {
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        if (processing && !inAppLoading) setProcessing(false)
    }, [inAppLoading, processing, setProcessing])

    const cardTabs = [
        {
            name: 'overview',
            title: 'Overview',
            element: <DistributorOverview canEdit={canEdit} distributor={distributor} dispatch={dispatch} setProcessing={setProcessing}/>
        },
        {
            name: 'territory_codes',
            title: 'Territory Codes',
            element: <DistributorTerritoryCodes canEdit={canEdit} distributor={distributor} dispatch={dispatch} />
        },
        {
            name: 'bill_types',
            title: 'Bill Types',
            element: <DistributorBillTypes distributor={distributor} canEdit={canEdit} dispatch={dispatch} />
        },
        {
            name: 'service_regions',
            title: 'Service Regions',
            element: <DistributorServiceRegions distributor={distributor}/>
        }
    ]

    return <DataCard canEdit={canEdit} title={distributor.name} actions={[]}>
        {inAppLoading && processing ? <Spinner size='s'/> : <Tabs options={cardTabs} />}
    </DataCard>
}

export default function DistributorsModule({ showModal, cancelModal, canEdit, setCanEdit, filters }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const me = useSelector(state => state.app.me);
    const inAppLoading = useSelector(state => state.app.inAppLoading);
    const distributors = useSelector(state => state.moveToBill.distributors);

    useEffect(() => {
		let canView =
			me.claims.filter(c => [MoveToBillPrivileges.READ_MTB_CONFIG_DATA, MoveToBillPrivileges.WRITE_MTB_CONFIG_DATA].includes(c.name))
				.length > 0;

		if (!canView) navigate('/');
		else {
			setCanEdit(me.claims.filter(c => c.name === MoveToBillPrivileges.WRITE_MTB_CONFIG_DATA).length > 0);

			if (!distributors) dispatch(MoveToBillActions.getDistributors());
			
		}
	}, [distributors, dispatch, navigate, me, setCanEdit]);

	return <>
        {(distributors || [])
        .filter((dist) => filters.name ? dist.name.toLowerCase().includes(filters.name.toLowerCase()) : true)
        .sort((a, b) => a.name > b.name ? 1: -1)
        .map(distributor => <DistributorCard 
            key={distributor.id} 
            distributor={distributor} 
            inAppLoading={inAppLoading}
            dispatch={dispatch} 
            canEdit={canEdit}
        />)}
    </>;
}
