import { MoveToBillActions  } from '../actions'

const { SAVE_DISTRIBUTORS, SAVE_AGENTS, SAVE_PRICING_PLANS } = MoveToBillActions.types

const initialState = {
    distributors: null,
    agents: null,
    pricingPlans: null
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SAVE_DISTRIBUTORS:
			return {
                ...state,
                distributors: action.payload
            }

        case SAVE_AGENTS:
            return {
                ...state,
                agents: action.payload
            }

        case SAVE_PRICING_PLANS:
            return {
                ...state,
                pricingPlans: action.payload
            }

		default:
			return state
	}
}
