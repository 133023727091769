export const types = {
    // Distributors
    GET_DISTRIBUTORS: "GET_DISTRIBUTORS",
    GET_DISTRIBUTOR: "GET_DISTRIBUTOR",

    SAVE_DISTRIBUTORS: "SAVE_DISTRIBUTORS",

    CREATE_DISTRIBUTORS: "CREATE_DISTRIBUTORS",
    UPDATE_DISTRIBUTOR: "UPDATE_DISTRIBUTOR",

    ADD_DISTRIBUTOR_TERRITORY_CODE: "ADD_DISTRIBUTOR_TERRITORY_CODE",
    REMOVE_DISTRIBUTOR_TERRITORY_CODE: "REMOVE_DISTRIBUTOR_TERRITORY_CODE",


    // Agents
    GET_AGENTS: "GET_AGENTS",
    SAVE_AGENTS:"SAVE_AGENTS",
    UPDATE_AGENT: "UPDATE_AGENT",
    CREATE_AGENT: "CREATE_AGENT",
    DELETE_AGENT: "DELETE_AGENT",

    // Pricing Plans
    GET_PRICING_PLANS: "GET_PRICING_PLANS",
    SAVE_PRICING_PLANS: "SAVE_PRICING_PLANS",
    CREATE_PRICING_PLAN: "CREATE_PRICING_PLAN",

    GET_PRICING_PLAN: "GET_PRICING_PLAN",
    UPDATE_PRICING_PLAN: "UPDATE_PRICING_PLAN",
    DELETE_PRICING_PLAN: "DELETE_PRICING_PLAN",
    
    UPDATE_PRICING_PLAN_ATTRIBUTES: "UPDATE_PRICING_PLAN_ATTRIBUTES",
    UPDATE_PRICING_PLAN_COMPONENTS: "UPDATE_PRICING_PLAN_COMPONENTS"
}

// Distributors
export const getDistributors = () => ({type: types.GET_DISTRIBUTORS})

export const saveDistributors = payload => ({type: types.SAVE_DISTRIBUTORS, payload})

export const getDistributor = payload => ({type: types.GET_DISTRIBUTOR, payload})

export const createDistributors = payload => ({type: types.CREATE_DISTRIBUTORS, payload})

export const updateDistributor = payload => ({type: types.UPDATE_DISTRIBUTOR, payload})
