import checkAccess from "./checkAccess";
import cookieService from './cookieService'
import downloadCSVFromJson from "./downloadCSVFromJSON";
import formatDate from "./formatDate";
import passPropsToChild from './passPropsToChild'
import ReRoute from './reRoute'


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
    capitalizeFirstLetter,
    checkAccess,
    cookieService,
    downloadCSVFromJson,
    formatDate,
    passPropsToChild,
    ReRoute
}
