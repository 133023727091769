import { put, takeEvery, select, call } from "redux-saga/effects";
import Call from "../../../lib/api/fetch";
import { AppActions, MoveToBillActions } from '../../actions'

const types = MoveToBillActions.types

export function* executeGetDistributors(){
    yield put(AppActions.loading(true))

    let res = yield call(() => Call('/move-to-bill/distributors'))

    yield put(MoveToBillActions.saveDistributors(res.data))

    yield put(AppActions.loading(false))
}

export function* executeGetDistributor(action){
    yield put(AppActions.inAppLoading(true))

    let res = yield call(() => Call('/move-to-bill/distributors/' + action.payload))

    const distributors = yield select(state => state.moveToBill.distributors) || []

    yield put(MoveToBillActions.saveDistributors(distributors.map(d => {
        if (d.id === action.payload) return res.data
        else return d
    })))

    yield put(AppActions.inAppLoading(false))
}

export function* executeUpdateDistributor(action){
    const { payload } = action;

    yield put(AppActions.inAppLoading(true))

    let res = yield call(() => Call('/move-to-bill/distributors/' + payload.id, "PUT", payload))

    const distributors = yield select(state => state.moveToBill.distributors) || []

    yield put(MoveToBillActions.saveDistributors(distributors.map(d => {
        if (d.id === payload.id) return {...d, ...res.data}
        else return d
    })))

    yield put(AppActions.inAppLoading(false))
}

export function* executeGetAgents(action){
    yield put(AppActions.loading(true))

    const res = yield Call('/move-to-bill/agents')

    yield put({type: types.SAVE_AGENTS, payload: res.data})
    
    yield put(AppActions.loading(false))
}

export function* executeUpdateAgent(action){
    yield put(AppActions.inAppLoading(true))

    const { payload } = action

    const res = yield Call('/move-to-bill/agents/' + payload.id, 'PUT', payload);

    const agents = yield select(state => state.moveToBill.agents) || [];

    // Replace the matching agent ID object with the newly updated record
    yield put({type: types.SAVE_AGENTS, payload: agents.map((agent, a) => agent.id === payload.id ? res.data : agent)});
    
    yield put(AppActions.inAppLoading(false));
}

export function* executeCreateAgent(action){
    yield put(AppActions.loading(true))

    const { payload } = action

    const res = yield Call('/move-to-bill/agents', 'POST', [{
        name: payload.name,
        code: payload.code
    }]);

    const agents = yield select(state => state.moveToBill.agents) || [];

    // Replace the matching agent ID object with the newly updated record
    yield put({type: types.SAVE_AGENTS, payload: [...agents, ...res.data]});
    
    yield put(AppActions.loading(false));
}

export function* executeDeleteAgent(action){
    yield put(AppActions.loading(true))

    const { payload } = action

    yield Call('/move-to-bill/agents/' + payload, 'DELETE');

    const agents = yield select(state => state.moveToBill.agents) || [];

    // Replace the matching agent ID object with the newly updated record
    yield put({type: types.SAVE_AGENTS, payload: agents.filter((agent, a) => agent.id !== payload)});
    
    yield put(AppActions.loading(false));
}

export function* executeGetPricingPlans(){
    yield put(AppActions.loading(true))

    const res = yield call(() => Call('/move-to-bill/pricingPlans'))

    yield put({type: MoveToBillActions.types.SAVE_PRICING_PLANS, payload: res.data})

    yield put(AppActions.loading(false))
}

export function* executeGetPricingPlan(action){
    yield put(AppActions.inAppLoading(true))

    const res = yield call(() => Call('/move-to-bill/pricingPlans/' + action.payload))

    const pricingPlans = yield select(state => state.moveToBill.pricingPlans)

    yield put({
        type: MoveToBillActions.types.SAVE_PRICING_PLANS, 
        payload: pricingPlans.map(pp => pp.id === action.payload ? res.data : pp)
    })

    yield put(AppActions.inAppLoading(false))
}

export function* executeUpdatePricingPlanAttributes(action){
    const {id, update} =  action.payload

    yield put(AppActions.inAppLoading(true))

    yield call(() => Call('/move-to-bill/pricingPlans/' + id + '/attributes', 'PUT', update))

    const pricingPlans = yield select(state => state.moveToBill.pricingPlans)

    yield put({
        type: MoveToBillActions.types.SAVE_PRICING_PLANS, 
        payload: pricingPlans.map(pp => pp.id === id ? {
            ...pp,
            "attributes": update
        } : pp)
    })

    yield put(AppActions.inAppLoading(false))
}


export function* executeUpdatePricingPlanComponents(action){
    const {id, update} =  action.payload

    yield put(AppActions.inAppLoading(true))

    yield call(() => Call('/move-to-bill/pricingPlans/' + id + '/components', 'PUT', update))

    const pricingPlans = yield select(state => state.moveToBill.pricingPlans)

    yield put({
        type: MoveToBillActions.types.SAVE_PRICING_PLANS, 
        payload: pricingPlans.map(pp => pp.id === id ? {
            ...pp,
            "components": update
        } : pp)
    })

    yield put(AppActions.inAppLoading(false))
}

export default function* MoveToBillWatcher() {
	yield takeEvery(types.GET_DISTRIBUTORS, executeGetDistributors);
    yield takeEvery(types.GET_DISTRIBUTOR, executeGetDistributor);
    yield takeEvery(types.UPDATE_DISTRIBUTOR, executeUpdateDistributor);
    yield takeEvery(types.GET_AGENTS, executeGetAgents);
    yield takeEvery(types.UPDATE_AGENT, executeUpdateAgent);
    yield takeEvery(types.CREATE_AGENT, executeCreateAgent);
    yield takeEvery(types.DELETE_AGENT, executeDeleteAgent);

    yield takeEvery(types.GET_PRICING_PLANS, executeGetPricingPlans);
    yield takeEvery(types.GET_PRICING_PLAN, executeGetPricingPlan);
    yield takeEvery(types.UPDATE_PRICING_PLAN_ATTRIBUTES, executeUpdatePricingPlanAttributes);
    yield takeEvery(types.UPDATE_PRICING_PLAN_COMPONENTS, executeUpdatePricingPlanComponents);
};
