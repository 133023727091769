import { capitalizeFirstLetter } from '../../lib/helpers';
import Button from '../button';
import Checkbox from '../checkBox';
import InputBox from '../inputBox';
import InputBoxList from '../inputBoxList';
import SelectBox from '../selectBox';
import Tags from '../tags';
import './form.scss';

export const formField = ({
	fieldName,
	label,
	type,
	onChange = () => null,
	onSelect,
	onClick,
	value,
	readOnly,
	options = [],
	hidden = false,
	placeholder,
	singleSelectOnly = false,
	onDelete = () => null,
	element,
}) => ({
	fieldName,
	label,
	type,
	onChange,
	onSelect,
	onClick,
	value,
	readOnly,
	options,
	hidden,
	placeholder,
	singleSelectOnly,
	onDelete,
	element,
});

const getFormField = ({field, errors, values, setValues, canEdit}) => {
	let {
		fieldName,
		label = capitalizeFirstLetter(fieldName),
		type,
		onChange = ({ target: { value } }) => setValues({...values, [fieldName]: value}),
		onSelect,
		onClick,
		value,
		readOnly = !canEdit,
		options = [],
		hidden = false,
		placeholder,
		singleSelectOnly = false,
		onDelete = ({ target: { value } }) => null		
	} = field;

	value = value || values[fieldName];

	if (hidden) return <></>;

	switch (type) {
		case 'select':
			let selected = [];

			if (singleSelectOnly) selected = options.filter(o => o.id === value);
			else selected = options.filter(o => value.includes(o.id));

			return (
				<SelectBox
					label={label}
					disabled={readOnly}
					placeholder={placeholder}
					options={options}
					selected={selected}
					singleSelectOnly={singleSelectOnly}
					onChange={onChange}
					onSelect={onSelect}
					onDelete={onDelete}
					errorMessage={errors[fieldName]}
				/>
			);

		case 'checkbox':
			return (
				<Checkbox
					label={label}
					readOnly={readOnly}
					options={options}
					onClick={onClick}
					onChange={onChange}
					errorMessage={errors[fieldName]}
				/>
			);

		case 'section':
			return <div>{value}</div>;

		case 'custom':
			return field.element;

		case 'array':
			return (
				<InputBoxList
					values={value}
					label={label}
					onChange={onChange}
					placeholder={placeholder}
					readOnly={readOnly}
					errorMessage={errors[fieldName]}
				/>
			);
		
		case 'label':
			return <label className={"label"}>{label}</label>

		case 'tags':
			return <Tags
				values={value}
				onAdd={onChange}
				onRemove={onDelete}
				placeholder={placeholder}
				label={label}
				readOnly={readOnly}
			/>

		default:
			return (
				<InputBox
					label={label}
					value={value || ''}
					onChange={onChange}
					readOnly={readOnly}
					errorMessage={errors[fieldName]}
				/>
			);
	}
};

export default function Form({
	fields = [],
	values = {},
	setValues=null,
	errors = {},
	onSubmit,
	actions = [],
	canEdit = true,
	...props
}) {
	if (!onSubmit) {
		onSubmit = e => {
			e.preventDefault();
			let formValues = {};
			fields.map(({ fieldName, value }) => (formValues[fieldName] = value));
		};
	}

	let className = 'formContainer';

	if (props.className) className += ' ' + props.className;

	return (
		<form className={className} onSubmit={onSubmit}>
			{fields.map((field, fdx) => (
				<div className="formField fullWidth" key={fdx}>
					{getFormField({field, errors, values, setValues, canEdit})}
				</div>
			))}
			{canEdit && (
				<div className="formActions flex justifyCenter">
					{actions.map(({ buttonType = 'primaryButton', onClick, type = 'button', text }, adx) => (
						<div key={adx} className="formAction">
							<Button buttonType={buttonType} onClick={onClick} type={type}>
								{text}
							</Button>
						</div>
					))}
				</div>
			)}
		</form>
	);
}
