import { Form } from "../../../components";
import { useState } from "react";
import { MoveToBillActions } from "../../../redux/actions";
import { States } from "../../../lib/constants";


const PRODUCT_TYPES = ["Fixed", "Index", "Variable"];

const PRODUCT_COMMODITIES = ["Electric", "Gas"];

const PRODUCT_BILL_TYPES = ["UBR", "URR", "Dual"];

const PRODUCT_BILLING_POINTS = ["Meter", "Distribution"];


const PricingPlanAttributes = ({ canEdit, pricingPlan={ attributes: {} }, dispatch, cancelEdit, setProcessing }) => {
    const [values, setValues] = useState(pricingPlan.attributes);
	const [errors, setErrors] = useState({});

    const updateValue = (e, property) => setValues({...values, [property]: e.target.value})

    const flagNames = {
        "has_grt": "GRT Included",
        "cap_pt": "Capacity Pass Through",
        "trans_pt": "Transmission Pass Through",
        "ces_pt": "CES Pass Through",
        "tots_pt": "TOTS Pass Through",
        "has_blocks": "Has Blocks"
    }

    const fields = [
        {
            fieldName: "commodity",
            type: "select",
            options: PRODUCT_COMMODITIES.map(pc => ({id: pc, name: pc})),
            singleSelectOnly: true
        },
        {
            fieldName: "product",
            type: "select",
            options: PRODUCT_TYPES.map(pt => ({id: pt, name: pt})),
            singleSelectOnly: true
        },
        {
            fieldName: "states",
            type: "select",
            placeholder: 'Select eligible states',
            options: Object.getOwnPropertyNames(States).sort().map(s => ({id: s, name: s})),
            onChange: ({target: { value }}) => setValues({...values, states: [...(values.states || []), value].sort() }),
            onDelete: state => setValues({...values, states: [...(values.states || [])].filter(x => x !== state)}),
            value: values.states || []
        },
        {
            fieldName: "bill_types",
            label: 'Bill Types',
            type: "select",
            placeholder: 'Select eligible bill types',
            options: PRODUCT_BILL_TYPES.sort().map(s => ({id: s, name: s})),
            onChange: ({target: { value }}) => setValues({...values, bill_types: [...(values.bill_types || []), value].sort() }),
            onDelete: bt => setValues({...values, bill_types: [...(values.bill_types || [])].filter(x => x !== bt)}),
            value: values.bill_types || []
        },
        {
            fieldName: 'billing_point',
            label: 'Billing Point',
            type: 'checkbox',
            options: PRODUCT_BILLING_POINTS.map(bp => ({id: bp, name: bp, checked: values.billing_point === bp})),
            onClick: e => updateValue(e, "billing_point"),
            onChange: () => true
        },
        {
            fieldName: 'interruptible',
            label: 'Is Interruptible',
            type: 'checkbox',
            options: [{id: 'interruptible', name: 'Interruptible', checked: !!values.interruptible}],
            onClick: () => setValues({...values, interruptible: !values.interruptible}),
            onChange: () => true,
            hidden: values.commodity === 'Electric'
        },
        {
            fieldName: 'zones',
            label: 'Zones',
            type: 'tags',
            onChange: ({target: { value }}) => setValues({...values, zones: [...(values.zones || []), value] }),
            onDelete: ({target: { value }}) => setValues({...values, zones: (values.zones || []).filter(zone => zone !== value)}),
            // onChange: (v) => console.log(v),
            hidden: values.commodity === 'Gas'
        },
        {
            type: 'label',
            label: 'Pass Throughs'
        },
        ...["has_grt","cap_pt","trans_pt","ces_pt","tots_pt","has_blocks"].map(fieldName => ({
            fieldName,
            label: null,
            type: 'checkbox',
            hidden: values.commodity !== 'Electric',
            options: [{id: fieldName, name: flagNames[fieldName] || fieldName, checked: !!values[fieldName]}],
            onClick: () => setValues({...values, [fieldName]: !values[fieldName]})
        }))
    ]   

    const actions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit },
		{ text: 'Update', type: 'submit' },
    ]

    const onSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        dispatch({
            type: MoveToBillActions.types.UPDATE_PRICING_PLAN_ATTRIBUTES, 
            payload: {
                id: pricingPlan.id,
                update: values
            }
        })
    }

    return <Form fields={fields} canEdit={canEdit} values={values} setValues={setValues} errors={errors} onSubmit={onSubmit} actions={actions}/>
}

export default PricingPlanAttributes;
