import './inputBox.scss';


export default function InputBox({
    id, 
    label, 
    value, 
    onChange, 
    placeholder, 
    readOnly, 
    className, 
    checked, 
    type='text',
    errorMessage,
    fullWidth=false,
    textAlign='left',
    onSubmit=null
}){
    let inputClassNames = ["input"]

    if (readOnly) inputClassNames.push("readOnly")

    return <div 
        className={["inputBoxContainer", 'flex-column', className].join(' ')} 
        style={{width: fullWidth ? '100%' : 'inherit'
    }}>
        {label && <label className={"label"} htmlFor={id}>{label}</label>}
        <input
            checked={checked}
            type={type}
            className={inputClassNames.join(' ')}
            value={value} 
            placeholder={placeholder} 
            onChange={onChange} 
            id={id} 
            readOnly={readOnly}
            style={{textAlign: textAlign}}
            onKeyDown={e => {
                if (e.key === 'Enter' && !!onSubmit){
                    e.preventDefault();
                    onSubmit()
                    // Synthesize form clearing
                    onChange({target: { value : ''}})
                }
            }}
        />
        {errorMessage && <p className={"errorMessage"}>{errorMessage}</p>}
    </div>
}