import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MoveToBillActions } from '../../redux/actions';
import { Form, DataCard, Spinner, Modal, DeleteModal} from '../../components';
import { Icons, MoveToBillPrivileges } from '../../lib/constants';
import { capitalizeFirstLetter } from '../../lib/helpers';


function AgentOverview({canEdit, agent={}, dispatch, cancelEdit, setProcessing}) {
    const [values, setValues] = useState(agent);
	const [errors, setErrors] = useState({});

	const onSubmit = e => {
		e.preventDefault();
		let errObj = {};

		if (!values.name || values.name.trim() === '') errObj = { ...errObj, name: 'Enter valid agent name' };

        if (!values.code || values.code.trim() === '') errObj = { ...errObj, code: 'Enter valid agent code' };

		if (Object.keys(errObj).length > 0) {
			setErrors(errObj);
			return;
		}

        setProcessing(true)

        // If exists
        if (!!agent.id) dispatch({type: MoveToBillActions.types.UPDATE_AGENT, payload: values})
        // If not 
        else {
            dispatch({type: MoveToBillActions.types.CREATE_AGENT, payload: values})
            setProcessing(false)
        }
    };

    const fieldNames = {
        "esg_id": 'ESG ID',
        'company_name': "Company Name"
    }

    const fieldOnChange = {
        'esg_id': ({ target: { value } }) => setValues({ ...values, esg_id: parseInt(value)})
    }

    const statusOptions = [
        {id:'Active', name: 'Active'},
        {id:'Inactive', name: 'Inactive'},
        {id:'Closed', name: 'Closed'}
    ]

    const handleAliasChanges = ({value, index}) => {
        if (index === -1){
            setValues({...values, aliases: [...values.aliases, ""]})
        }
        else if (value == null){
            setValues({...values, aliases: values.aliases.filter((alias, a) => a !== index) })
        }
        else{
            setValues({...values, aliases: values.aliases.map((alias, a) => a === index ? value : alias ) })
        }
    }

	const fields = [
		{
			fieldName: 'name',
			label: 'Name',
			value: values.name || '',
			readOnly: !!values.id,
			onChange: ({ target: { value } }) => setValues({ ...values, name: value }),
		},
        {
			fieldName: 'code',
			label: 'Code',
			value: values.code || '',
			readOnly: !!values.id,
			onChange: ({ target: { value } }) => setValues({ ...values, code: value })
		},
        {
            fieldName: 'is_external',
            options: [{id: 'external', name: 'External', checked: values.is_external || false}],
            type: 'checkbox',
            hidden: !values.id,
            onClick: () => setValues({...values, is_external: !values.is_external})
        },
        {
            fieldName: 'status',
            label: 'Status',
            type: 'select',
            hidden: !values.id,
            readOnly: !canEdit,
            options: statusOptions,
            value: values.status || '',
            onChange: ({target: { value }}) => setValues({...values, status: value}),
            singleSelectOnly: true
        },
        // General rules for fields that are update-able
        ...['esg_id', 'company_name', 'description'].map(field => ({
			fieldName: field,
			label: fieldNames[field] || capitalizeFirstLetter(field),
			value: values[field] || '',
            hidden: !values.id,
			readOnly: !canEdit,
			onChange: fieldOnChange[field] || (({ target: { value } }) => setValues({ ...values, [field]: value }))
		})),
        {
            label: 'Aliases',
            hidden: !values.id,
            type: 'array',
            fieldName: 'aliases',
            value: values.aliases || [],
            onChange: handleAliasChanges
        }
	];

	const formActions = [
		{ buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit },
		{ text: values.id ? 'Update' : 'Create', type: 'submit' },
	];

	return <Form className={'fullWidth'} fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />;
}

function AgentCard({canEdit, agent, dispatch, inAppLoading=false }) {
    const [processing, setProcessing] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)

    useEffect(() => {
        if (processing && !inAppLoading) setProcessing(false)
    }, [inAppLoading, processing, setProcessing])

    const handleDelete = () => {
        dispatch({type: MoveToBillActions.types.DELETE_AGENT, payload: agent.id})
        setDeleteMode(false)
    }

    const cardActions = [{icon: Icons.DELETE, handler: () => setDeleteMode(true)}]

    return <>
        <DataCard canEdit={canEdit} title={agent.name} actions={cardActions}>
            {inAppLoading && processing 
                ? <Spinner size='s'/> 
                : <AgentOverview canEdit={canEdit} agent={agent} dispatch={dispatch} setProcessing={setProcessing}/>
            }
        </DataCard>
        <DeleteModal 
            deleteMode={deleteMode}
            setDeleteMode={setDeleteMode}
            title={"Delete agent " + agent.name + ' ?'}
            warningMessage={"This will delete all of their aliases also"}
            onDelete={handleDelete}
        />
    </>
}

export default function AgentsModule({ showModal, cancelModal, canEdit, setCanEdit, filters }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

    const { me, inAppLoading } = useSelector(state => state.app);
    
    const agents = useSelector(state => state.moveToBill.agents);

    useEffect(() => {
		let canView =
			me.claims.filter(c => [MoveToBillPrivileges.READ_MTB_CONFIG_DATA, MoveToBillPrivileges.WRITE_MTB_CONFIG_DATA].includes(c.name))
				.length > 0;

		if (!canView) navigate('/');
		else {
			setCanEdit(me.claims.filter(c => c.name === MoveToBillPrivileges.WRITE_MTB_CONFIG_DATA).length > 0);

			if (!agents) dispatch({type: MoveToBillActions.types.GET_AGENTS});
			
		}
	}, [agents, dispatch, navigate, me, setCanEdit]);

	return <>
        {(agents || [])
        .filter((agent) => filters.name ? agent.name.toLowerCase().includes(filters.name.toLowerCase().trim()) : true)
        .sort((a, b) => a.name > b.name ? 1: -1)
        .map(agent => <AgentCard 
            key={agent.id} 
            agent={agent} 
            inAppLoading={inAppLoading}
            dispatch={dispatch}
            canEdit={canEdit}
        />)
        }
        <Modal
            title={'Add Agent'}
            show={showModal}
            cancel={cancelModal}
        >
            <AgentOverview 
                canEdit={canEdit}
                dispatch={dispatch}
                cancelEdit={cancelModal}
                setProcessing={() => cancelModal()}
            />
        </Modal>
    </>;
}
