import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MoveToBillActions } from '../../../redux/actions';
import { Form, DataCard, Spinner, Modal, Tabs } from '../../../components';
import { MoveToBillPrivileges } from '../../../lib/constants';
import { capitalizeFirstLetter } from '../../../lib/helpers';
import PricingPlanAttributes from './attributes';
import PricingPlanComponents from './components';

function PricingPlanOverview({ canEdit, pricingPlan = {}, dispatch, cancelEdit, setProcessing }) {
	useEffect(() => {
		if (!!pricingPlan.id && !pricingPlan.attributes) {
			setProcessing(true);
			dispatch({ type: MoveToBillActions.types.GET_PRICING_PLAN, payload: pricingPlan.id });
		}
	}, [pricingPlan.id, pricingPlan.attributes, dispatch, setProcessing]);

	const [values, setValues] = useState(pricingPlan);
	const [errors, setErrors] = useState({});

	const onSubmit = e => {
		e.preventDefault();

		let errObj = {};

		if (!values.name || values.plan_description.trim() === '')
			errObj = { ...errObj, name: 'Enter valid pricing plan name' };

		if (Object.keys(errObj).length > 0) {
			setErrors(errObj);
			return;
		}

		setProcessing(true);
		// If exists
		if (!!pricingPlan.id) dispatch({ type: MoveToBillActions.types.UPDATE_PRICING_PLAN, payload: values });
		// If not
		else dispatch({ type: MoveToBillActions.types.CREATE_PRICING_PLAN, payload: values });
	};

	const fieldNames = {};

	const fieldOnChange = {
		pricing_plan_id: ({ target: { value } }) => setValues({ ...values, pricing_plan_id: parseInt(value) }),
		vendor_id: ({ target: { value } }) => setValues({ ...values, vendor_id: parseInt(value) }),
		package_id: ({ target: { value } }) => setValues({ ...values, package_id: parseInt(value) }),
		record: ({ target: { value } }) => setValues({ ...values, record: parseInt(value) }),
	};

	const statusOptions = [
		{ id: 'Active', name: 'Active' },
		{ id: 'Inactive', name: 'Inactive' },
		{ id: 'Closed', name: 'Closed' },
	];

	const fields = [
		{
			fieldName: 'plan_description',
			label: 'Plan Description',
			value: values.plan_description || '',
			readOnly: !!values.id,
			onChange: ({ target: { value } }) => setValues({ ...values, name: value }),
		},
		{
			fieldName: 'service_type_description',
			label: 'Service Type Description',
			type: 'select',
			hidden: !values.id,
			readOnly: !canEdit,
			options: [
				{ id: 'Gas', name: 'Gas' },
				{ id: 'Electric', name: 'Electric' },
			],
			value: values.service_type_description || '',
			onChange: ({ target: { value } }) => setValues({ ...values, service_type_description: value }),
			singleSelectOnly: true,
		},
		{
			fieldName: 'status',
			label: 'Status',
			type: 'select',
			hidden: !values.id,
			readOnly: !canEdit,
			options: statusOptions,
			value: values.status || '',
			onChange: ({ target: { value } }) => setValues({ ...values, status: value }),
			singleSelectOnly: true,
		},
		// General rules for fields that are update-able
		...['pricing_plan_id', 'vendor_id', 'package_id', 'record', 'rate_code', 'package_name'].map(field => ({
			fieldName: field,
			label: fieldNames[field] || capitalizeFirstLetter(field),
			value: values[field] || '',
			hidden: !values.id,
			readOnly: !canEdit,
			onChange: fieldOnChange[field] || (({ target: { value } }) => setValues({ ...values, [field]: value })),
		})),
	];

	const formActions = [
		{ buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit },
		{ text: values.id ? 'Update' : 'Create', type: 'submit' },
	];

	return (
		<Form
			className={'fullWidth'}
			fields={fields}
			errors={errors}
			onSubmit={onSubmit}
			actions={formActions}
			canEdit={canEdit}
		/>
	);
}

function PricingPlanCard({ canEdit, pricingPlan, dispatch, inAppLoading = false }) {
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		if (processing && !inAppLoading) setProcessing(false);
	}, [inAppLoading, processing, setProcessing]);

	const tabOptions = [
		{
			name: 'overview',
			title: 'Overview',
			element: (
				<PricingPlanOverview
					canEdit={canEdit}
					pricingPlan={pricingPlan}
					dispatch={dispatch}
					setProcessing={setProcessing}
				/>
			),
		},
		{
			name: 'attributes',
			title: 'Attributes',
			element: (
				<PricingPlanAttributes
					canEdit={canEdit}
					pricingPlan={pricingPlan}
					dispatch={dispatch}
					setProcessing={setProcessing}
				/>
			),
		},
		{
			name: 'components',
			title: 'Components',
			element: (
				<PricingPlanComponents
					canEdit={canEdit}
					pricingPlan={pricingPlan}
					dispatch={dispatch}
					setProcessing={setProcessing}
				/>
			),
		},
	];

	return (
		<DataCard canEdit={canEdit} title={pricingPlan.plan_description} actions={[]}>
			{inAppLoading && processing ? <Spinner size="s" /> : <Tabs options={tabOptions} />}
		</DataCard>
	);
}

export default function PricingPlans({ showModal, cancelModal, canEdit, setCanEdit, filters }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { me, inAppLoading } = useSelector(state => state.app);

	const pricingPlans = useSelector(state => state.moveToBill.pricingPlans);

	useEffect(() => {
		let canView =
			me.claims.filter(c =>
				[MoveToBillPrivileges.READ_MTB_CONFIG_DATA, MoveToBillPrivileges.WRITE_MTB_CONFIG_DATA].includes(c.name),
			).length > 0;

		if (!canView) navigate('/');
		else {
			setCanEdit(me.claims.filter(c => c.name === MoveToBillPrivileges.WRITE_MTB_CONFIG_DATA).length > 0);

			if (!pricingPlans) dispatch({ type: MoveToBillActions.types.GET_PRICING_PLANS });
		}
	}, [pricingPlans, dispatch, navigate, me, setCanEdit]);

	return (
		<>
			{(pricingPlans || [])
				.filter(pricingPlan =>
					filters.name ? pricingPlan.plan_description.toLowerCase().includes(filters.name.toLowerCase()) : true,
				)
				.sort((a, b) => (a.plan_description > b.plan_description ? 1 : -1))
				.map(pricingPlan => (
					<PricingPlanCard
						key={pricingPlan.id}
						pricingPlan={pricingPlan}
						inAppLoading={inAppLoading}
						dispatch={dispatch}
						canEdit={canEdit}
					/>
				))}
			<Modal title={'Add Pricing Plan'} show={showModal} cancel={cancelModal}>
				<PricingPlanOverview
					canEdit={canEdit}
					dispatch={dispatch}
					cancelEdit={cancelModal}
					setProcessing={() => true}
				/>
			</Modal>
		</>
	);
}
