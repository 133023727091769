import { Page } from "../../components";
import GenerateModule  from './generate'
import DistributorsModule from "./distributors";
import AgentsModule from './agents'
import PricingPlansModule from "./pricingPlans";
import { useState } from "react";

const sideBarRoutes = [
	{title: 'Generate', route: '/move-to-bill/generate'},
	{title: 'Distributors', route: '/move-to-bill/distributors'},
    {title: 'Agents', route: '/move-to-bill/agents'},
    {title: 'Pricing Plans', route: '/move-to-bill/pricing-plans'}
]

export function Generate() {
	return <Page 
		pageName={'Generate'}
        Component={GenerateModule}
		sideBarRoutes={sideBarRoutes}
	/>
}


export function Agents() {
	const [filters, setFilters] = useState({
		name: ""
	})

	return <Page 
		pageName={'Agents'}
        Component={AgentsModule}
		componentProps={{filters}}
		sideBarRoutes={sideBarRoutes}
		searchBar={[
			{
				field: 'name',
				placeholder: 'Search agent',
				onChange: ({target: { value }}) => setFilters({...filters, name: value})
			}
		]}
	/>
}


export function Distributors() {
	const [filters, setFilters] = useState({
		name: ""
	})

	return <Page 
		pageName={'Distributors'}
        Component={DistributorsModule}
		componentProps={{filters}}
		sideBarRoutes={sideBarRoutes}
		searchBar={[
			{
				field: 'name',
				placeholder: 'Search distributor',
				onChange: ({target: { value }}) => setFilters({...filters, name: value})
			}
		]}
		includeAddButton={false}
	/>
}


export function PricingPlans() {
	const [filters, setFilters] = useState({name: ""})

	return <Page 
		pageName={'Pricing Plans'}
        Component={PricingPlansModule}
		sideBarRoutes={sideBarRoutes}
		componentProps={{filters, setFilters}}
		searchBar={[
			{
				field: 'name',
				placeholder: 'Search pricing plan',
				onChange: ({target: { value }}) => setFilters({...filters, name: value})
			}
		]}
		addButtonObj={'Pricing Plan'}
	/>
}
