import AdminReducer from './admin'
import AppReducer from './app'
import CommodityProductsReducer from './commodityProducts'
import MoveToBillReducer from './moveToBill'
import UtilitiesReducer from './utilities'
import WeatherReducer from './weather'

import { combineReducers } from 'redux'

export default combineReducers(
  {
    admin: AdminReducer,
    app: AppReducer,
    commodityProducts: CommodityProductsReducer,
    moveToBill: MoveToBillReducer,
    utilities: UtilitiesReducer,
    weather: WeatherReducer
  }
)
